<template>
    <div>
         <div class="is-pulled-right">
            <router-link to="/"><button class="button is-danger is-pulled-right">Home</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <div v-if="loading===false">
            <div class="card"  >
                <div class="card-content">
                <h3 class="subtitle" >
               Hours Accumulated
                </h3>
                <p><strong>Total : {{ totalHours }}</strong></p>
                <p>Training : {{ trainingTotalHours }}</p>
                
                <p>Matches : {{ matchesTotalHours }}</p>
                <p>Events : {{ eventsTotalHours }}</p>
                
                </div>
            </div>
            <br />
            <div class="tabs" >
                <ul>
                    <li :class="{'is-active' : tab === 'training'}" @click="tab='training'"><a>Training</a></li>
                    <li :class="{'is-active' : tab === 'matches'}" @click="tab='matches'"><a>Matches</a></li>
                   <li :class="{'is-active' : tab === 'events'}" @click="tab='events'"><a>Events</a></li>
                </ul>
            </div>

            <table class="table is-fullwidth is-striped" v-if="tab=='training'">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in volunteer.training" :key="row.id" >
                        <td :class="{'is-success' : row.pass === 1 || row.fail === 1}">{{ row.date }}</td>
                        <td :class="{'is-success' : row.pass === 1 || row.fail === 1}">{{ row.name }}</td>
                        <td :class="{'is-success' : row.pass === 1 || row.fail === 1}">{{ row.hours }}</td>
                    </tr>
                </tbody>
            </table>
             <table class="table is-fullwidth is-striped" v-if="tab=='events'">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in volunteer.events" :key="row.id">
                        <td :class="{'is-success' : row.attended === 1}">{{ row.date }}</td>
                        <td :class="{'is-success' : row.attended === 1}">{{ row.name }}</td>
                        <td :class="{'is-success' : row.attended === 1}">{{ row.hours }}</td>
                    </tr>
                </tbody>
            </table>
            <table class="table is-fullwidth is-striped" v-if="tab=='matches'">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Hours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in volunteer.meetups" :key="row.id">
                        <td >{{ row.date }}</td>
                        <td>{{ row.hours }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
       
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false,
      tab: 'training'
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    
  },
  computed: {
    trainingTotalHours: function() {

        let trainingTotal = 0

        let activeTraining = this.volunteer.training.filter(row => row.pass ===1 || row.fail === 1)

        if(activeTraining.length > 0)
        {
        const reducer = (accumulator, currentValue) => accumulator + currentValue.hours; 
        trainingTotal = activeTraining.reduce(reducer, 0)
        }


        return trainingTotal
    },
    matchesTotalHours: function() {

        let matchesTotal = 0

        if(this.volunteer.meetups && this.volunteer.meetups.length > 0)
        {
        const reducer = (accumulator, currentValue) => accumulator + currentValue.hours; 
        matchesTotal = this.volunteer.meetups.reduce(reducer, 0)
        }


        return matchesTotal
    },
    eventsTotalHours: function() {

        let eventsTotal = 0

        if(this.volunteer.events)
        {
            let attendedEvents = this.volunteer.events.filter(row => row.attended === 1)

            if(attendedEvents.length > 0)
            {
            const reducer = (accumulator, currentValue) => accumulator + currentValue.hours; 
            eventsTotal = attendedEvents.reduce(reducer, 0)
            }
        }

        return eventsTotal
    },
    totalHours: function() {
        return this.trainingTotalHours + this.matchesTotalHours + this.eventsTotalHours
    }
  }
}
</script>
