<template>
  <div class="matches">
     <div class="is-pulled-right">
            <router-link to="/"><button class="button is-danger is-pulled-right">Home</button></router-link>
            </div>
      <h3 class="subtitle">Your Matches</h3>
      
        <div class="notification is-warning" v-if="loading===true">
        
        Loading matches ...
      </div>

      <div class="notification is-info" v-if="loading===false && matches.length == 0">
        You have no matches yet
      </div>
      
      <div v-for="row in matches" :key="row.id">
        <div class="card">
            <div class="card-content">
              <h3 class="subtitle" >
              Match with {{ row.young_person_name }}
              </h3>
              <p>Started : {{ row.start_date }}</p>
            <p>Worker : {{ row.user_forename }} {{ row.user_surname }}</p>
              
            </div>
            <footer class="card-footer" v-if="row.completed==0">
              <p class="card-footer-item">
                <span>
                  <router-link :to="'/matches/' + row.id + '/meetups'">Meetups</router-link>
                </span>
              </p>
              <p class="card-footer-item">
                <span>
                  <router-link :to="'/matches/' + row.id + '/spending'">Spending</router-link>
                </span>
              </p>
            </footer>
            <footer class="card-footer" v-if="row.completed==1">
              <p class="card-footer-item">
                <span>
                  <strong>Completed</strong>
                </span>
              </p>
              
            </footer>
        </div>
        <br />
      </div>
      
  </div>
</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewMatches',
  data: function() {
    return {
      matches: [],
      access_token: '',
      loading:true
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerMatches();
      });
    
    
  },
  methods: {
    async getVolunteerMatches() {
        this.loading = true
        VolunteerService.getVolunteerMatches(this.accessToken)
        .then(
            (matches => {
            this.$set(this, "matches", matches);
            this.loading = false
            }).bind(this)
        );
    }
  }
}
</script>

