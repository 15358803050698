<template>
    <div>
         <div class="is-pulled-right">
            <router-link to="/personal_details"><button class="button is-danger is-pulled-right">Back</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <form v-if="loading==false">
            <div class="field">
                <label class="label">Forename</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.forename">
                </div>
            
             </div>
             <div class="field">
                <label class="label">Surname</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.surname">
                </div>
            
             </div>
             <div class="field">
                <label class="label">Email</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.email">
                </div>
            
             </div>
             <div class="field">
                <label class="label">Telephone</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.telephone">
                </div>
            
             </div>
             <div class="field">
                <label class="label">Date of Birth</label>
                <div class="control">
                    <input class="input" type="date"  required v-model="volunteer.dob">
                </div>
            
             </div>

              <div class="field">
                <label class="label">Address</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.address1">
                    <input class="input" type="text"  required v-model="volunteer.address2" v-if="volunteer.address2 != ''">
                    <input class="input" type="text"  required v-model="volunteer.city" v-if="volunteer.city != ''">
                    <input class="input" type="text"  required v-model="volunteer.postcode" v-if="volunteer.postcode != ''">
                </div>
            
             </div>
             

        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    
  }
}
</script>
