<template>
    <div>
        <div class="is-pulled-right">
            <router-link to="/matches"><button class="button is-danger ">Back to Matches</button></router-link>
            </div>
 <div class="">
            <router-link :to="'/matches/' + stream_match_id + '/add_spending'"><button class="button is-success ">Add Spending</button></router-link>
            </div><br />
         <h3 class="subtitle">Match Spending <span v-if="loading===false">(Budget : &pound;{{ stream_match.budget }})</span></h3>
        <div class="notification is-warning" v-if="loading===true">
        Loading meetups ...
      </div>
      <div class="notification is-info" v-if="error===true">
        This match cannot be found or you are not authorised to see this information
      </div>
        <table class="table is-fullwidth is-striped" v-if="loading===false && error===false">
          <thead>
              <thead>
                    <tr>    
                            <th>Date</th>
                            <th>Amount (&pound;)</th>
                            <th width="15">&nbsp;</th>
                    </tr>   
                </thead>
                <tbody>
                    <tr v-for="row in stream_match.spendings" :key="row.id">
                        <td>{{ row.date }}</td>
                        <td>{{ row.amount }}</td>
                        <td>
                            <a :href="row.receipt_url" v-if="row.receipt_url" target="_blank">
                                <span class="icon">
                                    <i class="fas fa-receipt"></i>
                                </span>
                            </a>

                        </td>
                    </tr>
                    
                
              </tbody>
              <tfoot>

                
                     <tr >
                        <td>&nbsp;</td>
                        <td><strong>{{ spending_total.toFixed(2) }}</strong></td>
                        <td>&nbsp;</td>
                    </tr>
                    </tfoot>
          </table>
          
     
        </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewMatches',
  data: function() {
    return {
      stream_match: [],
      access_token: '',
      loading:true,
      error: false,
      stream_match_id : this.$route.params.id
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerMatch();
      });
    
    
  },
  methods: {
    async getVolunteerMatch() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerMatch(this.accessToken, this.stream_match_id)
        .then(
            (stream_match => {
            this.$set(this, "stream_match", stream_match);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This match cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    }
  },
  computed: {
    spending_total: function() {
      if (!this.stream_match.spendings) {
            return 0;
        }

        return this.stream_match.spendings.reduce(function (total, value) {
            return total + Number(value.amount);
        }, 0);
    }
  }
}
</script>

