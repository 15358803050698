<template>
    <div>
         <div class="is-pulled-right">
            <router-link to="/personal_details"><button class="button is-danger is-pulled-right">Back</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <form v-if="loading==false && volunteer.accessni.length > 0">
            <div class="field">
                <label class="label">Reference Number</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.accessni[0].code">
                </div>
            
             </div>
            
             <div class="field">
                <label class="label">Issue Date</label>
                <div class="control">
                    <input class="input" type="text"  required v-model="volunteer.accessni[0].issue_date">
                </div>
            
             </div>

             <div class="notification is-danger" v-if="loading==false && volunteer.accessni[0].expired == 1">
                Your Access NI has expired. You must have a valid Access NI to volunteer with MACs.
            </div>

             
             

        </form>

        <div class="notification is-info" v-if="loading==false && volunteer.accessni.length == 0">
            There have been no Access NI details stored so far. If you have recently applied, it will appear on this screen as soon as it is approved and added to the database.
        </div>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false

            console.log(volunteer.accessni)
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    
  }
}
</script>
