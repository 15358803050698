<template>
    <div>
        
         <div class="is-pulled-right">
            <router-link to="/volunteer_progress"><button class="button is-danger is-pulled-right">Back</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <form v-if="loading==false">
            
            <form>
                 <div class="field">
                    <label class="label">Entry Date</label>
                    <div class="control">
                        <input class="input" type="date"  required v-model="outcome_date">
                    </div>
                
                </div>  

                <div class="field" v-for="row in outcome_questions" :key="row.id">
                    <label class="label"><strong>{{ row.title}}</strong> - {{ row.text }} </label>
                    <div class="control">
                        <select class="input" v-model="answers[row.id]">
                            <option v-for="option in row.options" :key="option.id" :value="option.value">{{ option.value }} - {{ option.text }}</option>
                        </select>
                    </div>
                
                </div>   
                

                <div class="buttons">
                    <button type="button" :disabled="this.isSubmitting == true" class="button is-success" @click="saveOutcome()">Save Answers </button>
                </div>

            </form>

            
             

        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false,
      outcome_questions: [],
      outcome_date: new Date().toISOString().slice(0,10),
      answers: [],
      isSubmitting: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
          this.getOutcomeQuestions();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    async getOutcomeQuestions() {
        VolunteerService.getSimpleApiData(this.accessToken, "outcome_questions")
        .then(response => {
            this.outcome_questions = response;
            
            
        })
    },
    async saveOutcome() {
        this.isSubmitting = true
        var formdata = { 
            volunteer_id : this.volunteer.id,
            date : this.outcome_date,
            answers: this.answers
        }; 

        VolunteerService.saveOutcome(this.accessToken, formdata)
        .then((response) => {
                this.isSubmitting = false
                console.log(response)
               this.$router.push("/volunteer_progress");
            
            }).catch((error) => {
                alert("Something has gone wrong, please try again")
                console.log("Error:" + JSON.stringify(error.response.data));
               
            this.isSubmitting = false
        });
    },
    
  }
}
</script>
