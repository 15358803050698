<template>
    <div>
        <div class="is-pulled-right">
            <router-link to="/matches"><button class="button is-danger is-pulled-right">Back to Matches</button></router-link>
            </div>
         <h3 class="subtitle">Match Meetups</h3>
        <div class="notification is-warning" v-if="loading===true">
        Loading meetups ...
      </div>
      <div class="notification is-info" v-if="error===true">
        This match cannot be found or you are not authorised to see this information
      </div>
        <table class="table is-fullwidth is-striped" v-if="loading===false && error===false">
          <thead>
              <tr>
                  <th>Meetups</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in approved_meetups" :key="row.id">
                    <td>Date : {{ row.date }}
                      <span v-if="row.meetup_type == 3 && row.approved == 0 && row.rejected == 0" class="tag is-info">Requested</span>
                      <span v-if="row.meetup_type == 3 && row.approved == 1 " class="tag is-success">Approved</span>
                      <span v-if="row.meetup_type == 3 && row.rejected == 1 " class="tag is-danger">Rejected</span>
                      
                      <br />Hours : <span v-if="row.hours > 0">{{ row.hours }}</span><br />{{ row.notes }}</td>
                  </tr>
              </tbody>
          </table>
          
          <table class="table is-fullwidth is-striped" v-if="loading===false && error===false && requests.length > 0">
          <thead>
              <tr>
                  <th>Requests</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in requests" :key="row.id">
                    <td>
                      Date : {{ row.date }}
                      <span v-if="row.meetup_type == 3 && row.approved == 0 && row.rejected == 0" class="tag is-warning">Requested</span>
                      <span v-if="row.meetup_type == 3 && row.rejected == 1 " class="tag is-danger">Rejected</span>
                      <br />
                    <span v-if="row.time != '00:00:00'">Time : {{ row.time }}<br /></span>
                    Venue : {{ row.venue }}<br />
                    {{ row.notes }}
                    </td>
                  </tr>
              </tbody>
          </table>

          <div class="" >
            <router-link :to="'/matches/' + this.$route.params.id + '/add_request'"><button class="button is-success ">Submit a Meetup Request</button></router-link>
            </div>
          
     
        </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewMatches',
  data: function() {
    return {
      stream_match: [],
      access_token: '',
      loading:true,
      show_error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerMatch();
      });
    
    
  },
  methods: {
    async getVolunteerMatch() {
        this.loading = true
        this.error = false
        var stream_match_id = this.$route.params.id
        VolunteerService.getVolunteerMatch(this.accessToken, stream_match_id)
        .then(
            (stream_match => {
            this.$set(this, "stream_match", stream_match);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This match cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    }
  },
  computed : {
      approved_meetups: function () {
       
        let filteredMeetups =  this.stream_match.meetups.filter(function(u) {
            if(  u.meetup_type == 1 || u.meetup_type == 2 || (u.meetup_type == 3 && (u.approved == 1 || u.approved == 1))) 
            {
              return 1
            }
          })

          return filteredMeetups
       
     },
     requests: function () {
       
        let filteredMeetups =  this.stream_match.meetups.filter(function(u) {
            if(  u.meetup_type == 3 && ( (u.approved == 0 && u.rejected == 0) || u.rejected == 1)) 
            {
              return 1
            }
          })

          return filteredMeetups
       
     }
  },
}
</script>

