<template>
    <div class=" content">
         <div class="is-pulled-right">
            <router-link to="/"><button class="button is-danger is-pulled-right">Home</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <form v-if="loading==false">
            <h2 class="title">MACS Volunteer Agreement</h2>
            <p>We are delighted to welcome you as a Volunteer Mentor at MACS. We look forward to supporting you in this position and thank you for the decision to give your time to our charity. </p>
            <p>Here’s how we hope to ensure this experience is the best it can be for you, the MACS young people and the charity itself. </p>
            <p>Please refer to the MACS Volunteer Handbook for further detail on the points below. </p>
            
            
            <h3 class="title">MACS Agree to:</h3>
            <ul>
                <li>Provide a full recruitment and induction process. </li>
<li>Treat all MACS Volunteers with respect and in line with MACS organisational policies. </li>
<li>Reimburse you for your travel and match budget expenses and provide clear information on how to claim them. </li>	
<li>Support Volunteers to carry out your role through 1-1 and group supervisions, on call support and weekly contact when matched. </li>
<li>Cover all volunteers with by our organisation’s Public Liability Insurance. </li>
<li>Provide access the MACS Volunteer Handbook which includes all policies and procedures. </li>
<li>Provide information on MACS additional training / upcoming events / groups via email.  </li>
<li>Keep volunteer personnel and practice file in line with General Data Protection Regulations.</li>

            </ul>

            <p>*If you feel that we are not meeting your expectations in our commitments, you have the right to make a formal Complaint – See the Volunteer Handbook for details on this. </p>

            <h3 class="title">MACS Volunteers Agree to:</h3>
            <ul>
                <li>Complete an interview, training and induction process before volunteering at MACS. </li>
                <li>Adhere to role descriptions, MACS Policies and Procedures and Code of Conduct outlined in the training / induction process and Volunteer Handbook. </li>
                <li>Implement the knowledge and skills gained from training. </li>
                <li>Attend monthly supervision when matched with a young person.  </li>
                <li>Ensure you have open and honest communication throughout your volunteering experience with MACS. </li>


            </ul>

            <h3 class="title">You may be asked to leave your volunteering role if:</h3>
            <ul>
                <li>MACS feel that you are not keeping to the agreement.</li>
            <li>You do not actively volunteer in over 1 year. </li>
            </ul>

            <p>In any of these situations you will be contacted and invited to discuss your options in line with our Policies and Procedures. </p>

            <br />
            <div class="buttons">
                <button class="button is-link" @click="agree()">I Have Read and Agree</button>
            </div>
             

        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async agree() {
        this.loading = true

        var formdata = { 
            _method: 'patch',
            agreement_signed : 1
        };

        VolunteerService.signAgreement(this.accessToken, formdata, this.volunteer.id)
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false

            this.$router.push("/");

            }).bind(this)
        ).catch((error) => {
            this.loading = false
            console.log(error)
            alert("Something when wrong when updating the database")
        });
    },    
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    
  }
}
</script>


