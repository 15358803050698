<template>
    <div>
        <div class="is-pulled-right">
        <router-link :to="'/matches/' + stream_match_id + '/spending'"><button class="button is-danger is-pulled-right">Back</button></router-link>
        </div><br /><br />
        
        <form @submit.prevent="saveMatchSpending()" enctype="multipart/form-data">
            <div class="field">
                <label class="label">Date</label>
                <div class="control">
                    <input class="input" type="date"   v-model="spending_date" required>
                </div>
            
             </div>
             
             <div class="field">
                <label class="label">Amount</label>
                <div class="control">
                   <input type="number" min="0.50" step="0.01" class="input" placeholder="£" v-model="spending_amount" required> 
                </div>
                </div>

             <div class="field">
                <label class="label">Receipt</label>
                <div class="control">
                   <input type="file" class="input" name="fileupload" ref="fileupload" id="fileupload" placeholder="Upload or take a picture" required/><br />
                </div>
            
             </div>

             <div class="field">
                <label class="label">Description</label>
                <div class="control">
                   <textarea class="textarea" v-model="spending_description"  >
                    </textarea>
                </div>
                </div>

             <div class="control">
                 <button class="button is-primary" :disabled="saving">Save</button>
            </div>
        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewMatches',
  data: function() {
    return {
      stream_match: [],
      access_token: '',
      loading:true,
      show_error: false,
      stream_match_id : this.$route.params.id,
      saving: false,
      // Spending fields
      spending_date: new Date().toISOString().slice(0,10),
    spending_amount: '',
    spending_description: '',
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          //this.getVolunteerMatch();
      });
    
    
  },
  methods: {
    async getVolunteerMatch() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerMatch(this.accessToken, this.stream_match_id)
        .then(
            (stream_match => {
            this.$set(this, "stream_match", stream_match);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This match cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
     async saveMatchSpending() {
        this.saving = true;
        
        let file = this.$refs.fileupload.files[0];
        
        let formdata = new FormData();
        formdata.append('upload', file);
        formdata.append('date', this.spending_date);
        formdata.append('amount', this.spending_amount);
        formdata.append('description', this.spending_description);
        formdata.append('stream_match_id', this.stream_match_id);

        VolunteerService.saveMatchSpending(this.accessToken, formdata)
        .then((response) => {
                console.log(response)
                this.$router.push("/matches/" + this.stream_match_id + '/spending');
                
            }).catch((error) => {
            console.log(error)
            alert("Something has gone wrong saving the spending. Please try again or contact support.")
            this.saving = false;
        });
        
    },
  }
}
</script>

<style scoped>
textarea.input {height:50px;}

</style>
