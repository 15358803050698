<template>
    <div>
        <div class="is-pulled-right">
        <router-link :to="'/matches/' + stream_match_id + '/meetups'"><button class="button is-danger is-pulled-right">Back</button></router-link>
        </div><br /><br />
        
        <form @submit.prevent="saveMatchRequest()" >
            <div class="field">
                <label class="label">Date</label>
                <div class="control">
                    <input class="input" type="date"   v-model="request_date" required>
                </div>
            
             </div>

             <div class="field">
                <label class="label">Time</label>
                <div class="control">
                    <input class="input" type="time"   v-model="request_time" >
                </div>
            
             </div>

             <div class="field">
                <label class="label">Number of Hours</label>
                <div class="control">
                    <input class="input" type="number" step="0.25"   v-model="request_hours" required>
                </div>
            
             </div>

             <div class="field">
                <label class="label">Venue</label>
                <div class="control">
                    <input class="input" type="text"   v-model="request_venue" >
                </div>
            
             </div>
             
            
             <div class="field">
                <label class="label">Description of Meetup</label>
                <div class="control">
                   <textarea class="textarea" v-model="request_notes" rows="10" required>
                    </textarea>
                </div>
                </div>

             <div class="control">
                 <button class="button is-primary" :disabled="saving">Save</button>
            </div>
        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewMatches',
  data: function() {
    return {
      stream_match: [],
      access_token: '',
      loading:true,
      show_error: false,
      stream_match_id : this.$route.params.id,
      saving: false,
      // Spending fields
      request_date: new Date().toISOString().slice(0,10),
        request_notes: '',
        request_time: '',
        request_venue: '',
        request_hours: 1
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          //this.getVolunteerMatch();
      });
    
    
  },
  methods: {
    
     async saveMatchRequest() {
        this.saving = true;
        
        
        let formdata = new FormData();
        formdata.append('date', this.request_date);
        formdata.append('time', this.request_time);
        formdata.append('hours', this.request_hours);
        formdata.append('venue', this.request_venue);
        formdata.append('notes', this.request_notes);
        formdata.append('stream_match_id', this.stream_match_id);
        formdata.append('meetup_type', 3);

        VolunteerService.saveMatchRequest(this.accessToken, formdata)
        .then((response) => {
                console.log(response)
                this.$router.push("/matches/" + this.stream_match_id + '/meetups');
                
            }).catch((error) => {
            console.log(error)
            alert("Something has gone wrong saving the request. Please try again or contact support.")
            this.saving = false;
        });
        
    },
  }
}
</script>

<style scoped>
textarea.input {height:50px;}

</style>
