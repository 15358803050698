<template>
    <div>
         <div class="is-pulled-left">
            <router-link to="/add_outcome"><button class="button is-success is-pulled-right">Add New</button></router-link>
            </div>
         
         <div class="is-pulled-right">
            <router-link to="/personal_details"><button class="button is-danger is-pulled-right">Back</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

      <div class="notification is-info" v-if="loading===false && volunteer.volunteer_outcomes.length == 0">
        You have not yet entered any information
      </div>

        <form v-if="loading==false">
            
             <div v-for="row in volunteer.volunteer_outcomes" :key="row.id">
                <div class="card">
                    <div class="card-content">
                    <h3 class="subtitle" >
                    Date : {{ row.date }}
                    </h3>
                    
                    </div>
                    <footer class="card-footer" >
                    <p class="card-footer-item">
                        <span>
                        <router-link :to="'/outcomes/' + row.id">View</router-link>
                        </span>
                    </p>
                   
                    </footer>
                   
                </div>
                <br />
            </div>
             

        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ContactDetails',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    
  }
}
</script>
