<template>
    <div>
        
         <div class="is-pulled-right">
            <router-link to="/volunteer_progress"><button class="button is-danger is-pulled-right">Back</button></router-link>
            </div><br /><br />
        
        <div class="notification is-warning" v-if="loading===true">
        Loading details ...
      </div>

        <form v-if="loading==false">
            
            <form>
                 <div class="field">
                    <label class="label">Entry Date</label>
                    <div class="control">
                        <input class="input" type="text"  readonly v-model="outcome_date">
                    </div>
                
                </div>  

                <div class="field" v-for="row in outcome_questions" :key="row.id">
                    <label class="label"><strong>{{ row.title}}</strong> - {{ row.text }} </label>
                    <div class="control">
                        <select class="input" readonly v-model="answers[row.id]">
                            <option v-for="option in row.options" :key="option.id" :value="option.value">{{ option.value }} - {{ option.text }}</option>
                        </select>
                    </div>
                
                </div>   
                


            </form>

            
             

        </form>
    </div>

</template>

<script>
import VolunteerService from '@/services/VolunteerService.js';

export default {
  name: 'ViewOutcome',
  data: function() {
    return {
      volunteer: [],
      access_token: '',
      loading:true,
      error: false,
      outcome_questions: [],
      outcome_date: '',
      answers: [],
      outcome_id: this.$route.params.id
    }
  },
  created() {
    this.getAccessToken()
      .then(() => { 
          this.getVolunteerDetails();
          
      });
    
    
  },
  methods: {
    async getVolunteerDetails() {
        this.loading = true
        this.error = false
        
        VolunteerService.getVolunteerDetails(this.accessToken, "myself")
        .then(
            (volunteer => {
            this.$set(this, "volunteer", volunteer);
            this.loading = false

            this.getOutcomeQuestions();
            
            }).bind(this)
        ).catch((error) => {
            if(error.response.status === 404)
            {
                //alert("This details cannot be found or you are not authorised to see this information")
            }
            this.loading = false
            this.error = true
        });
    },
    async getOutcomeQuestions() {
        VolunteerService.getSimpleApiData(this.accessToken, "outcome_questions")
        .then(response => {
            this.outcome_questions = response;
            
           

            // Set answers to be the set matching the param id
            for(var j = 0; j< this.volunteer.volunteer_outcomes.length; j++)
            {
                if(this.volunteer.volunteer_outcomes[j].id == this.outcome_id)
                {
                     // Set the outcome date
                    this.outcome_date = this.volunteer.volunteer_outcomes[j].date 
                    
                    for(var k = 0; k < this.volunteer.volunteer_outcomes[j].answers.length; k++)
                    {
                        let outcome_question_id = this.volunteer.volunteer_outcomes[j].answers[k].outcome_question_id;
                        this.answers[outcome_question_id] = this.volunteer.volunteer_outcomes[j].answers[k].value;
                    }
                }
            }
            
        })
    },
    
  }
}
</script>
